import { ChainId } from '@uniswap/sdk-core'

const BLOCK_EXPLORER_PREFIXES: { [chainId: number]: string } = {
  [ChainId.MAINNET]: 'https://etherscan.io',
  [ChainId.GOERLI]: 'https://goerli.etherscan.io',
  [ChainId.SEPOLIA]: 'https://sepolia.etherscan.io',
  [ChainId.OPTIMISM]: 'https://optimistic.etherscan.io',
  [ChainId.OPTIMISM_GOERLI]: 'https://goerli-optimism.etherscan.io',
  [ChainId.POLYGON]: 'https://polygonscan.com',
  [ChainId.POLYGON_MUMBAI]: 'https://mumbai.polygonscan.com',
  [ChainId.CELO]: 'https://celoscan.io',
  [ChainId.CELO_ALFAJORES]: 'https://alfajores-blockscout.celo-testnet.org',
  [ChainId.BNB]: 'https://bscscan.com',
  [ChainId.AVALANCHE]: 'https://snowtrace.io',
  [168587773]:'https://testnet.blastscan.io/',
  [81457]:'https://blastscan.io/'
}

export enum ExplorerDataType {
  TRANSACTION = 'transaction',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block',
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export function getExplorerLink(chainId: number, data: string, type: ExplorerDataType): string {
  if (chainId === ChainId.ARBITRUM_ONE) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://arbiscan.io/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://arbiscan.io/address/${data}`
      case ExplorerDataType.BLOCK:
        return `https://arbiscan.io/block/${data}`
      default:
        return `https://arbiscan.io/`
    }
  }

  if (chainId == 421614) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://sepolia.arbiscan.io/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://sepolia.arbiscan.io/address/${data}`
      case ExplorerDataType.BLOCK:
        return `https://sepolia.arbiscan.io/block/${data}`
      default:
        return `https://sepolia.arbiscan.io/`
    }
  }
  if (chainId == 48899) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://explorer.zircuit.com/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://explorer.zircuit.com/address/${data}`
      case ExplorerDataType.BLOCK:
        return `https://explorer.zircuit.com/block/${data}`
      default:
        return `https://explorer.zircuit.com/`
    }
  }
  if (chainId == 48900) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://explorermainnet.zircuit.com/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://explorermainnet.zircuit.com/address/${data}`
      case ExplorerDataType.BLOCK:
        return `https://explorermainnet.zircuit.com/block/${data}`
      default:
        return `https://explorermainnet.zircuit.com/`
    }
  }


  if (chainId == 81457) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://blastscan.io/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://blastscan.io/address/${data}`
      case ExplorerDataType.BLOCK:
        return `https://blastscan.io/block/${data}`
      default:
        return `https://blastscan.io/`
    }
  }

  if (chainId == 168587773) {
    switch (type) {
      case ExplorerDataType.TRANSACTION:
        return `https://testnet.blastscan.io/tx/${data}`
      case ExplorerDataType.ADDRESS:
      case ExplorerDataType.TOKEN:
        return `https://testnet.blastscan.io/address/${data}`
      case ExplorerDataType.BLOCK:
        return `https://testnet.blastscan.io/block/${data}`
      default:
        return `https://testnet.blastscan.io/`
    }
  }

  const prefix = BLOCK_EXPLORER_PREFIXES[chainId] ?? 'https://etherscan.io'

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${data}`

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${data}`

    case ExplorerDataType.BLOCK:
      if (chainId === ChainId.OPTIMISM || chainId === ChainId.OPTIMISM_GOERLI) {
        return `${prefix}/tx/${data}`
      }
      return `${prefix}/block/${data}`

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${data}`
    default:
      return `${prefix}`
  }
}
