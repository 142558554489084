import Row from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const TabWrapper = styled(Row)<{ Tabtype?: number }>`
  grid-gap: 8px;
  height: 62px;
  width: fit-content;
  gap: ${({ Tabtype }) => (Tabtype ? '16px' : '0')};
  .tabItem {
    flex: 1;
    width: 109px;
    cursor: pointer;
  }
  .ros {
    background: linear-gradient(270deg, rgba(255, 204, 0, 0.3) 0%, rgba(20, 182, 0, 0.3) 50%, rgba(0, 126, 73, 0.3) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
interface TabProps {
  tabList: any
  curr: number
  setCurr: any
  Tabtype?: number //  默认 swap tab  1  Position tab
}

export default function NavTab({ tabList, curr, setCurr, Tabtype }: TabProps) {
  return (
    <TabWrapper Tabtype={Tabtype}>
      {tabList?.map((tab: any, i: number) => (
        <Row className="tabItem" key={i} onClick={() => setCurr(i)}>
          {curr == i ? (
            <ThemedText.TextPrimary fontWeight={700} fontSize={16} className="ros">
              {tab}
            </ThemedText.TextPrimary>
          ) : (
            <ThemedText.TextPrimary>{tab}</ThemedText.TextPrimary>
          )}
        </Row>
      ))}
    </TabWrapper>
  )
}
